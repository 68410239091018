import React from "react";
import Slide from "components/features/TwoColWithImage.js";
import StepSlide from "components/features/ImageWithSteps.js";
import QuestionSlide from "components/faqs/ExpandingText.js";
import tw from "twin.macro";
import styled from "styled-components";
import {
  PrimaryButton as PrimaryButtonBase,
} from "components/misc/Buttons.js";

import Image1 from "../../images/pages/summer-school/1.jpg";
import Image2 from "../../images/pages/summer-school/2.jpg";
import Image3 from "../../images/pages/summer-school/3.jpg";
import { SUMMER_SCHOOL_LINK } from "../../constants";

const PrimaryButton = styled(PrimaryButtonBase)((props) => [
  tw`mt-4 md:mt-4 text-xl inline-block mx-auto md:mx-0 w-full text-center`,
  props.buttonRounded && tw`rounded-full`,
]);

const ButtonContainer = tw.div`max-w-xs mx-auto flex items-center mt-2 mb-8`;

export default () => {
  return (
    <div>
      <Slide
        subheading=''
        heading="Summer School 2025"
        description={
          <>
          <div>
            <p className='pb-4'>
            Programul <strong>ISCEO Summer School</strong> este parte din conceptul <strong>Summer, Autumn, Winter şi Spring Opportunities for Everyone</strong>, în R. Moldova.
            </p>
            <p>
            <strong>ISCEO Summer School</strong> reprezintă o posibilitate ideală de practicare și îmbunătățire a deprinderilor lingvistice, orientată către copiii preocupați de studierea limbii engleze, a tradițiilor britanice și americane. <strong>Caracterul educativ și distractiv</strong> al cursului menține ambianța optimă pentru învățare, relaxare și socializare, într-un mediu în care <strong>se comunică doar în Limba Engleză</strong>. Programul se bazează pe <strong>modelul britanic</strong> de organizare a școlilor de vacanță și a confirmat, de-a lungul anilor, eficiența îmbinării activităților de studiu cu cele de recreere și divertisment.
            </p>
          </div>
          {SUMMER_SCHOOL_LINK && (
            <ButtonContainer>
              <PrimaryButton as="a" href={SUMMER_SCHOOL_LINK} target="_blank" rel="noreferrer">
                Înregistrare 2025
              </PrimaryButton>
            </ButtonContainer>
          )}
          </>
        }
        imageSrc={Image1}
      />
      <StepSlide
        subheading=''
        heading={
          <>
            Mod de organizare
          </>
        }
        textOnLeft={false}
        imageSrc={Image2}
        steps={[
          {
            heading: "Vârsta",
            description: "6-12 ani"
          },
          {
            heading: "Perioada de realizare",
            description: "4 săptămâni din Iunie și 4 săptămâni din August, cu durata minimă de participare de 1 săptămână"
          },
          {
            heading: "Desfășurare",
            description: "09:00-15:00 sau 09:00-17:00, de luni până vineri"
          },
          {
            heading: "Număr de cursanți în grup",
            description: "8-15 cursanți"
          },
          {
            heading: "Alimentație opțională",
            description: "Prânz și gustări alcătuite cu grijă și rigurozitate, inspirate din preferințele elevilor de până acum"
          },
        ]}
      />
      <QuestionSlide
        imageSrc={Image3}
        subheading=""
        heading='Particularitățile cursului'
        description=''
        faqs={[
          {
            question: "Componenta instructiv-educativă a programului",
            answer: (
              <>
                <p className='pb-4'>
                  Se realizează în timpul orelor academice, care se deosebesc semnificativ de lecțiile ordinare prin <strong>caracterul dinamic</strong> și <strong>motivant</strong>, iar materiile școlare abordate se predau doar în Limba Engleză.
                </p>
                <p className='pb-4'>
                  Materii incluse:
                </p>
                <ul className='text-left list-disc pl-5'>
                  <li>Limba Engleză și cultura comunicării;</li>
                  <li>Științe umanistice;</li>
                  <li>Artă culinară;</li>
                  <li>Dramă;</li>
                  <li>Educație tehnologică;</li>
                  <li>Arte Sociale.</li>
                </ul>
              </>
            )
          },
          {
            question: "Componenta distractivă",
            answer: (
              <div>
                <p className='pb-4'>
                Depunem toate eforturile ca participanții noștri să simtă pe deplin spiritul vacanței și să beneficieze de o pauză departe de rutina școlară, cu activități distractive și relaxante. Cursul include o <strong>varietate largă de activități sportive, sociale și culturale</strong>, copiii fiind încurajați să se încadreze în cele preferate:
                </p>
                <ul className='text-left list-disc pl-5'>
                  <li>Sport;</li>
                  <li>Artă plastică;</li>
                  <li>Educație muzicală;</li>
                  <li>Științe ale naturii;</li>
                  <li>Cluburi de conversații cu vorbitori nativi din Statele Unite și Marea Britanie;</li>
                  <li>Cluburi lingvistice;</li>
                  <li>Cinema;</li>
                  <li>Spectacole de muzică și dans.</li>
                </ul>
              </div>
            )
          },
          {
            question: "Dezvoltare personală",
            answer:(
              <p>
                Se realizează în <strong>cadrul training-urilor psihologice</strong>, conduse cu profesionalism de către psihologii noștri. Pe parcursul ședințelor de training, îi ajutăm pe copii să se descopere pe sine și pe ceilalți, să dea inteligență emoțiilor pe care le trăiesc, să se exprime și să construiască relații interpersonale armonioase cu cei din jur.
              </p>
            )
          }
        ]}
      />
      {SUMMER_SCHOOL_LINK && (
        <ButtonContainer style={{marginTop: '-3rem'}}>
          <PrimaryButton as="a" href={SUMMER_SCHOOL_LINK} target="_blank" rel="noreferrer">
            Înregistrare 2025
          </PrimaryButton>
        </ButtonContainer>
      )}
    </div>
  );
};
